<template>
  <div class="col-sm-12 col-md-12 col-lg-12 p-0">
    <!-- <b-icon-Whatsapp class="whastapp-icon" @click="sendToWhatsAppWeb()" /> -->
    <header class="fasteadHeader d-flex justify-content-around">
      <img
        src="../../assets/images/nova-logo-fastead-completo.png"
        alt="logo-fastead"
        class="my-2"
      />
      <img
        src="../../assets/images/concurseiro-logo-2.png"
        alt="logo-concurseiro"
        class="my-2"
      />
    </header>
    <div
      v-if="fastCadastroConcluido.status"
      class="d-flex flex-column justify-content-center align-items-center text-center body-page"
    >
      <h2 class="text-success my-3">
        {{ fastCadastroConcluido.message }}
      </h2>
      <h5>Verifique seu email para os próximos passos.</h5>
      <h6 class="my-2">
        Caso necessário,
        <a
          href="https://fastead.sprinthub.site/r/ja-sou-aluno"
          target="_blank"
          class="btn btn-primary mx-1"
          >clique aqui</a
        >
        para entrar em contato com a secretaria.
      </h6>
    </div>
    <div v-else class="bg-light">
      <div class="bg-white container justify-content-center">
        <div
          class="d-flex steps-header justify-content-between py-5 align-items-center"
        >
          <span class="steps-guide steps-guide-disabled text-center">
            Passo 1:<br />
            Matrícula
          </span>
          <b-icon-arrow-right class="steps-icon" />
          <span
            :class="
              steps === 2
                ? 'steps-guide-active text-center'
                : 'steps-guide text-center'
            "
          >
            Passo 2:<br />
            Cadastro
          </span>
          <b-icon-arrow-right class="steps-icon" />
          <span
            :class="
              steps === 3
                ? 'steps-guide-active text-center'
                : 'steps-guide text-center'
            "
          >
            Passo 3: <br />
            Documentos
          </span>
        </div>
        <div v-if="steps === 2">
          <div class="text-center justify-content-center d-flex flex-column">
            <h3>{{ this.fastCadastro.titulo_tela_cadastro }}</h3>
            <h6 class="bg-secondary rounded text-white mx-auto p-1">
              Prenchimento obrigatório dados do aluno.
            </h6>
          </div>

          <div class="row">
            <div class="col-12 p-4">
              <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-12 mb-2">
                  <h5>Dados da matrícula</h5>
                </div>
                <div
                  class="col-sm-12 col-md-12 col-lg-12 mb-2 d-flex flex-column"
                >
                  <span
                    >{{
                      fastCadastro.dados_turma
                        ? fastCadastro.dados_turma.sigla
                        : null
                    }}
                    -
                    {{
                      fastCadastro.dados_turma
                        ? fastCadastro.dados_turma.curso.nome_curso
                        : null
                    }}</span
                  >
                  <span
                    >Carga horária:
                    {{
                      fastCadastro.dados_turma
                        ? fastCadastro.dados_turma.curso.carga_horaria
                        : null
                    }}</span
                  >
                </div>
              </div>
              <div class="row my-2">
                <div class="col-sm-12 col-md-12 col-lg-12 mb-2">
                  <h5>Dados primários</h5>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-4">
                  <label
                    >Primeiro nome
                    <span
                      v-if="fastDadosObrigatorios.nome_razao"
                      class="text-danger"
                      >*</span
                    ></label
                  >
                  <div class="form-group">
                    <input
                      v-model="fastCadastro.nome_razao"
                      :class="
                        fastDadosObrigatorios.nome_razao &&
                        !fastCadastro.nome_razao
                          ? 'form-control text-danger border border-danger'
                          : 'form-control'
                      "
                      type="text"
                      autocomplete="no"
                    />
                  </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-4">
                  <label
                    >Sobrenome
                    <span
                      v-if="fastDadosObrigatorios.sobrenome_fantasia"
                      class="text-danger"
                      >*</span
                    ></label
                  >
                  <div class="form-group">
                    <input
                      v-model="fastCadastro.sobrenome_fantasia"
                      :class="
                        fastDadosObrigatorios.sobrenome_fantasia &&
                        !fastCadastro.sobrenome_fantasia
                          ? 'form-control text-danger border border-danger'
                          : 'form-control'
                      "
                      type="text"
                      autocomplete="no"
                    />
                  </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-4">
                  <label>Email <span class="text-danger">*</span></label>
                  <div class="form-group">
                    <input
                      :class="
                        fastCadastro.email
                          ? 'form-control'
                          : 'form-control text-danger border border-danger'
                      "
                      type="text"
                      v-model="fastCadastro.email"
                      readonly
                    />
                  </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-4">
                  <label
                    >Data de nascimento
                    <span
                      v-if="fastDadosObrigatorios.datanascimento_abertura"
                      class="text-danger"
                      >*</span
                    ></label
                  >
                  <div class="form-group">
                    <input
                      v-model="fastCadastro.datanascimento_abertura"
                      :class="
                        fastDadosObrigatorios.datanascimento_abertura &&
                        !fastCadastro.datanascimento_abertura
                          ? 'form-control text-danger border border-danger'
                          : 'form-control'
                      "
                      type="date"
                      autocomplete="no"
                    />
                    <small class="text-secondary">Necessário ter 19 anos ou mais.</small>
                  </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-4">
                  <label
                    >CPF
                    <span
                      v-if="fastDadosObrigatorios.cpf_cnpj"
                      class="text-danger"
                      >*</span
                    ></label
                  >
                  <div class="form-group">
                    <input
                      v-model="fastCadastro.cpf_cnpj"
                      v-mask="['###.###.###-##']"
                      :class="
                        fastDadosObrigatorios.cpf_cnpj && !fastCadastro.cpf_cnpj
                          ? 'form-control text-danger border border-danger'
                          : 'form-control'
                      "
                      type="text"
                      autocomplete="no"
                    />
                  </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-4">
                  <label>Sexo </label
                  ><span v-if="fastDadosObrigatorios.sexo" class="text-danger"
                    >*</span
                  >
                  <div class="form-group">
                    <select
                      v-model="fastCadastro.sexo"
                      :class="
                        fastDadosObrigatorios.sexo && !fastCadastro.sexo
                          ? 'form-control text-danger border border-danger'
                          : 'form-control'
                      "
                    >
                      <option value="">Selecione</option>
                      <option value="M">Masculino</option>
                      <option value="F">Feminino</option>
                      <option value="O">Outras opções</option>
                    </select>
                  </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-4">
                  <label
                    >Nacionalidade
                    <span
                      v-if="fastDadosObrigatorios.id_pais_pessoa"
                      class="text-danger"
                      >*</span
                    ></label
                  >
                  <img
                    class="mx-2 pb-1"
                    :src="
                      buscaInfoPais(fastCadastro.id_pais_pessoa, 'bandeira')
                    "
                  />
                  <div class="form-group">
                    <select
                      v-model="fastCadastro.id_pais_pessoa"
                      :class="
                        fastDadosObrigatorios.id_pais_pessoa &&
                        !fastCadastro.id_pais_pessoa
                          ? 'form-control text-danger border border-danger'
                          : 'form-control'
                      "
                    >
                      <option
                        v-for="pais in organizaNacionalidade"
                        :key="pais.id_pais"
                        :value="pais.id_pais"
                      >
                        {{ pais.nacionalidade }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-4">
                  <label>RG</label>
                  <span v-if="fastDadosObrigatorios.rg" class="text-danger"
                    >*</span
                  >
                  <div class="form-group">
                    <input
                      v-model="fastCadastro.rg"
                      :class="
                        fastDadosObrigatorios.rg && !fastCadastro.rg
                          ? 'form-control text-danger border border-danger'
                          : 'form-control'
                      "
                      type="text"
                      autocomplete="no"
                    />
                  </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-4">
                  <label>Órgão Emissor do RG</label>
                  <span
                    v-if="fastDadosObrigatorios.orgao_emissor"
                    class="text-danger"
                    >*</span
                  >
                  <div class="form-group">
                    <input
                      v-model="fastCadastro.orgao_emissor"
                      :class="
                        fastDadosObrigatorios.orgao_emissor &&
                        !fastCadastro.orgao_emissor
                          ? 'form-control text-danger border border-danger'
                          : 'form-control'
                      "
                      type="text"
                      autocomplete="no"
                    />
                  </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-4">
                  <label>Naturalidade</label>
                  <span
                    v-if="fastDadosObrigatorios.naturalidade"
                    class="text-danger"
                    >*</span
                  >
                  <small>(cidade de nascimento)</small>
                  <div class="form-group">
                    <input
                      v-model="fastCadastro.naturalidade"
                      :class="
                        fastDadosObrigatorios.naturalidade &&
                        !fastCadastro.naturalidade
                          ? 'form-control text-danger border border-danger'
                          : 'form-control'
                      "
                      type="text"
                      autocomplete="no"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-12 mb-2">
                  <h5>Telefone</h5>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-4">
                  <label
                    >DDI
                    <span
                      v-if="fastDadosObrigatorios.id_pais_telefone"
                      class="text-danger"
                      >*</span
                    ></label
                  >
                  <img
                    class="mx-2 pb-1"
                    :src="
                      buscaInfoPais(fastCadastro.id_pais_telefone, 'bandeira')
                    "
                  />
                  <div class="form-group">
                    <select
                      v-model="fastCadastro.id_pais_telefone"
                      :class="
                        fastDadosObrigatorios.id_pais_telefone &&
                        !fastCadastro.id_pais_telefone
                          ? 'form-control text-danger border border-danger'
                          : 'form-control'
                      "
                    >
                      <option
                        v-for="pais in organizaDDI"
                        :key="pais.id_pais"
                        :value="pais.id_pais"
                      >
                        {{ pais.iso3 }} +{{ pais.fone }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-4">
                  <label
                    >Telefone
                    <span
                      v-if="fastDadosObrigatorios.telefone"
                      class="text-danger"
                      >*</span
                    ></label
                  >
                  <div class="form-group">
                    <input
                      v-model="fastCadastro.telefone"
                      v-mask="['(##) ####-####', '(##) #####-####']"
                      type="text"
                      :class="
                        fastDadosObrigatorios.telefone && !fastCadastro.telefone
                          ? 'form-control text-danger border border-danger'
                          : 'form-control'
                      "
                      autocomplete="no"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-12 mb-2">
                  <h5>Endereço</h5>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-4">
                  <label
                    >País
                    <span
                      v-if="fastDadosObrigatorios.id_pais_endereco"
                      class="text-danger"
                      >*</span
                    ></label
                  >
                  <img
                    class="mx-2 pb-1"
                    :src="buscaInfoPais(fastCadastro.id_pais_endereco)"
                  />
                  <div class="form-group">
                    <select
                      v-model="fastCadastro.id_pais_endereco"
                      :class="
                        fastDadosObrigatorios.id_pais_endereco &&
                        !fastCadastro.id_pais_endereco
                          ? 'form-control text-danger border border-danger'
                          : 'form-control'
                      "
                    >
                      <option
                        v-for="pais in organizaPaises"
                        :key="pais.id_pais"
                        :value="pais.id_pais"
                      >
                        {{ pais.nome }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-4">
                  <label
                    >CEP
                    <span v-if="fastDadosObrigatorios.cep" class="text-danger"
                      >*</span
                    ></label
                  >
                  <div class="form-group">
                    <input
                      v-model="fastCadastro.cep"
                      v-mask="'#####-###'"
                      type="text"
                      :class="
                        fastDadosObrigatorios.cep && !fastCadastro.cep
                          ? 'form-control text-danger border border-danger'
                          : 'form-control'
                      "
                      autocomplete="no"
                      @keyup="buscaCEPAluno()"
                    />
                  </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-4">
                  <label
                    >Endereço
                    <span
                      v-if="fastDadosObrigatorios.logradouro"
                      class="text-danger"
                      >*</span
                    ></label
                  >
                  <div class="form-group">
                    <input
                      v-model="fastCadastro.logradouro"
                      type="text"
                      :class="
                        fastDadosObrigatorios.logradouro &&
                        !fastCadastro.logradouro
                          ? 'form-control text-danger border border-danger'
                          : 'form-control'
                      "
                      maxlength="100"
                      autocomplete="no"
                    />
                  </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-4">
                  <label
                    >Número
                    <span
                      v-if="fastDadosObrigatorios.numero"
                      class="text-danger"
                      >*</span
                    ></label
                  >
                  <div class="form-group">
                    <input
                      v-model="fastCadastro.numero"
                      type="text"
                      :class="
                        fastDadosObrigatorios.numero && !fastCadastro.numero
                          ? 'form-control text-danger border border-danger'
                          : 'form-control'
                      "
                      maxlength="10"
                      autocomplete="no"
                    />
                  </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-4">
                  <label
                    >Complemento
                    <span
                      v-if="fastDadosObrigatorios.complemento"
                      class="text-danger"
                      >*</span
                    ></label
                  >
                  <div class="form-group">
                    <input
                      v-model="fastCadastro.complemento"
                      type="text"
                      :class="
                        fastDadosObrigatorios.complemento &&
                        !fastCadastro.complemento
                          ? 'form-control text-danger border border-danger'
                          : 'form-control'
                      "
                      autocomplete="no"
                    />
                  </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-4">
                  <label
                    >Bairro
                    <span
                      v-if="fastDadosObrigatorios.bairro"
                      class="text-danger"
                      >*</span
                    ></label
                  >
                  <div class="form-group">
                    <input
                      v-model="fastCadastro.bairro"
                      type="text"
                      :class="
                        fastDadosObrigatorios.bairro && !fastCadastro.bairro
                          ? 'form-control text-danger border border-danger'
                          : 'form-control'
                      "
                      autocomplete="no"
                    />
                  </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-4">
                  <label
                    >Cidade
                    <span
                      v-if="fastDadosObrigatorios.cidade"
                      class="text-danger"
                      >*</span
                    ></label
                  >
                  <div class="form-group">
                    <input
                      v-model="fastCadastro.cidade"
                      type="text"
                      :class="
                        fastDadosObrigatorios.cidade && !fastCadastro.cidade
                          ? 'form-control text-danger border border-danger'
                          : 'form-control'
                      "
                      autocomplete="no"
                    />
                  </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-4">
                  <label
                    >Estado
                    <span v-if="fastDadosObrigatorios.uf" class="text-danger"
                      >*</span
                    ></label
                  >
                  <div class="form-group">
                    <input
                      v-model="fastCadastro.uf"
                      type="text"
                      :class="
                        fastDadosObrigatorios.uf && !fastCadastro.uf
                          ? 'form-control text-danger border border-danger'
                          : 'form-control'
                      "
                      maxlength="2"
                      autocomplete="no"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              class="col-sm-12 col-md-12 col-lg-12 d-flex justify-content-around my-3"
            >
              <button class="btn" @click="validaPasso2()">Avançar</button>
            </div>
          </div>
        </div>
        <div v-else-if="steps === 3">
          <div class="text-center justify-content-center d-flex flex-column">
            <h3>{{ this.fastCadastro.titulo_tela_cadastro }}</h3>
            <h6 class="bg-secondary rounded text-white mx-auto p-1">
              Preenchimento obrigatório documentos do aluno.
            </h6>
            <div>
              <div v-if="enviodocs.habilitaEnvio">
                <uploader
                  :file-status-text="statusText"
                  :options="optionsArquivo"
                  class="uploader-example"
                  @file-success="fileSuccess"
                  @file-added="fileValidation"
                >
                  <uploader-unsupport />
                  <uploader-drop>
                    <p class="aluno_font_color">
                      Arraste um
                      {{ fastDocumentoParaEnvio.nome_documento }} aqui ou
                    </p>
                    <uploader-btn :attrs="restrictArquivo">
                      Clique aqui para selecionar
                    </uploader-btn>
                  </uploader-drop>
                  <uploader-list />
                </uploader>
                <button
                  class="btn my-3"
                  @click="enviodocs.habilitaEnvio = false"
                >
                  Cancelar
                </button>
              </div>
              <table v-else class="table table-sm">
                <thead class="thead-dark">
                  <tr>
                    <th>Documento</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody
                  v-if="
                    fastDadosObrigatorios
                      .fast_secretaria_matricula_requisito_documento.length
                  "
                >
                  <tr
                    v-for="doc in fastDadosObrigatorios.fast_secretaria_matricula_requisito_documento"
                    :key="doc.id_matricula_requisito_documento"
                  >
                    <td>
                      {{ doc.nome_documento }}
                      <small v-if="doc.obrigatorio" class="text-danger"
                        >(obrigatório)</small
                      >
                      <small v-else class="text-secondary">(opcional)</small>
                    </td>
                    <td v-if="doc.docEnviado">
                      <span class="text-success">Documento enviado!</span>
                      <br />
                    </td>
                    <td v-else>
                      <span class="text-danger">Não enviado</span> <br />
                      <button
                        class="btn btn-send-file"
                        @click="mostraEnvio(doc)"
                      >
                        Clique para enviar
                      </button>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td>
                      <h6 class="m-4 text-center">
                        Não foram cadastrados documentos para envio.
                      </h6>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div
            v-if="!enviodocs.habilitaEnvio"
            class="col-sm-12 col-md-12 col-lg-12 d-flex justify-content-around my-3"
          >
            <button class="btn" @click="steps = 2">Voltar</button>
            <button class="btn btn-finaliza" @click="validaPasso3eFinaliza()">
              Finalizar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import methods from "@/methods";
import { settings } from "@/config";

export default {
  name: "CadastroAlunoExterno",
  mixins: [methods],
  data: function () {
    return {
      token: "",
      steps: 2,
      fastCadastro: {},
      fastDadosPaises: {},
      fastDadosObrigatorios: {},
      fastCadastroConcluido: { status: false, message: "" },
      fastDocumentoParaEnvio: {
        id_matricula_requisito: "",
        id_matricula_requisito_documento: "",
        id_plataforma: "",
        nome_documento: "",
        tipo_documento: "",
      },
      enviodocs: {
        urls: [],
        habilitaEnvio: false,
      },
      optionsArquivo: {
        target:
          settings.endApiFastEad +
          "api/fast_secretaria_turma_checkout_aluno/upload_documento",
        testChunks: false,
        chunkSize: 1024 * 1024 * 100, // 100MB
        singleFile: true,
        query: {
          token: this.$route.query.token,
        },
        method: "POST",
        categoryMap: {
          document: [
            "pdf",
            "odt",
            "doc",
            "docx",
            "ppt",
            "pptx",
            "gif",
            "jpg",
            "jpeg",
            "png",
          ],
        },
        parseTimeRemaining: function (timeRemaining, parsedTimeRemaining) {
          return parsedTimeRemaining
            .replace(/\syears?/, " anos")
            .replace(/\days?/, " dias")
            .replace(/\shours?/, " horas")
            .replace(/\sminutes?/, " minutos")
            .replace(/\sseconds?/, " segundos");
        },
      },
      restrictArquivo: {
        accept:
          "application/pdf,application/vnd.oasis.opendocument.text,application/msword,application/vnd.ms-powerpoint,image/*",
      },
      statusText: {
        success: "Sucesso",
        error: "Erro",
        uploading: "Fazendo upload...",
        paused: "Upload pausado",
        waiting: "Aguardando...",
      },
    };
  },
  created() {
    this.token = this.$route.query.token;
  },
  mounted() {
    this.$store.state.fastCarregando = false;
    if (this.token) {
      this.getDadosDePreCadastro();
    }
  },
  computed: {
    organizaNacionalidade() {
      let listaPaises = [];
      if (Array.isArray(this.fastDadosPaises)) {
        listaPaises = [...this.fastDadosPaises].filter(
          (pais) => pais.nacionalidade.trim() !== ""
        );
      }
      return listaPaises.length
        ? listaPaises.sort(this.reornedarArrayObjetos("nacionalidade"))
        : null;
    },
    organizaDDI() {
      let listaPaises = [];
      if (Array.isArray(this.fastDadosPaises)) {
        listaPaises = [...this.fastDadosPaises];
      }
      return listaPaises.length
        ? listaPaises.sort(this.reornedarArrayObjetos("iso3"))
        : null;
    },
    organizaPaises() {
      let listaPaises = [];
      if (Array.isArray(this.fastDadosPaises)) {
        listaPaises = [...this.fastDadosPaises];
      }

      return listaPaises.length
        ? listaPaises.sort(this.reornedarArrayObjetos("nome"))
        : null;
    },
  },
  methods: {
    async getDadosDePreCadastro() {
      return await new Promise((resolve, reject) => {
        try {
          fetch(
            settings.endApiFastEad +
              "api/fast_secretaria_turma_checkout_aluno/recupera_cadastro?token=" +
              this.token,
            this.fastAjaxOptions("GET")
          )
            .then((obj) => obj.json())
            .then((json) => {
              if (json.success === false) {
                this.fastCadastroConcluido.status = true;
                this.fastCadastroConcluido.message = json.error;
              } else {
                this.fastCadastro = json;
                this.fastDadosPaises = json.paises_codigo;
                this.fastDadosObrigatorios = json.requisitos;
                console.log("this.fastCadastro", this.fastCadastro);
                this.verificaSeDocJaFoiEnviado();
              }
              resolve(json);
            })
            .catch((error) => {
              console.log(error);
              reject(error);
            });
        } catch (e) {
          console.log(e);
          reject(e);
        }
      });
    },
    buscaInfoPais(codigo_pais, tipo_retorno) {
      if (Array.isArray(this.fastDadosPaises)) {
        let pais = [...this.fastDadosPaises].find(
          (pais) => pais.id_pais === codigo_pais
        );
        switch (tipo_retorno) {
          case "bandeira":
            return pais.img ? pais.img : null;
          case "nome":
            return pais.nome ? pais.nome : null;
          case "nacionalidade":
            return pais.nacionalidade ? pais.nacionalidade : null;
          case "abreviacao":
            return pais.iso3 ? pais.iso3 : null;
          default:
            break;
        }
      }
    },
    reornedarArrayObjetos(key, order = "asc") {
      return function innerSort(a, b) {
        const varA = typeof a[key] === "string" ? a[key].toUpperCase() : a[key];
        const varB = typeof b[key] === "string" ? b[key].toUpperCase() : b[key];
        let comparison = 0;
        if (varA > varB) {
          comparison = 1;
        } else if (varA < varB) {
          comparison = -1;
        }
        return order === "desc" ? comparison * -1 : comparison;
      };
    },
    validaErrosForm() {
      let errors = [];
      if (this.fastDadosObrigatorios.cpf_cnpj && !this.fastCadastro.cpf_cnpj)
        errors.push("CPF não pode estar vazio");
      if (
        this.fastDadosObrigatorios.nome_razao &&
        !this.fastCadastro.nome_razao
      )
        errors.push("Nome não pode estar vazio");
      if (
        this.fastDadosObrigatorios.sobrenome_fantasia &&
        !this.fastCadastro.sobrenome_fantasia
      )
        errors.push("Sobrenome não pode estar vazio");
      if (
        this.fastDadosObrigatorios.datanascimento_abertura &&
        !this.fastCadastro.datanascimento_abertura
      )
        errors.push("Data de nascimento não pode estar vazio");
      if (this.fastDadosObrigatorios.sexo && !this.fastCadastro.sexo)
        errors.push("Campo sexo não pode estar vazio");
      if (
        this.fastDadosObrigatorios.id_pais_pessoa &&
        !this.fastCadastro.id_pais_pessoa
      )
        errors.push("Nacionalidade não pode estar vazio");
      if (
        this.fastDadosObrigatorios.naturalidade &&
        !this.fastCadastro.naturalidade
      )
        errors.push("Naturalidade não pode estar vazio");
      if (this.fastDadosObrigatorios.rg && !this.fastCadastro.rg)
        errors.push("RG não pode estar vazio");
      if (
        this.fastDadosObrigatorios.orgao_emissor &&
        !this.fastCadastro.orgao_emissor
      )
        errors.push("Órgão emissor do RG não pode estar vazio");
      if (this.fastDadosObrigatorios.cep && !this.fastCadastro.cep)
        errors.push("CEP não pode estar vazio");
      if (this.fastDadosObrigatorios.uf && !this.fastCadastro.uf)
        errors.push("Unidade Federal (UF) não pode estar vazio");
      if (
        this.fastDadosObrigatorios.logradouro &&
        !this.fastCadastro.logradouro
      )
        errors.push("Endereço (logradouro) não pode estar vazio");
      if (this.fastDadosObrigatorios.bairro && !this.fastCadastro.bairro)
        errors.push("Bairro não pode estar vazio");
      if (this.fastDadosObrigatorios.cidade && !this.fastCadastro.cidade)
        errors.push("Cidade não pode estar vazio");
      if (this.fastDadosObrigatorios.numero && !this.fastCadastro.numero)
        errors.push("Número do endereço não pode estar vazio");
      if (
        this.fastDadosObrigatorios.complemento &&
        !this.fastCadastro.complemento
      )
        errors.push("Complemento do endereço não pode estar vazio");
      if (
        this.fastDadosObrigatorios.id_pais_endereco &&
        !this.fastCadastro.id_pais_endereco
      )
        errors.push("País do endereço não pode estar vazio");
      if (this.fastDadosObrigatorios.telefone && !this.fastCadastro.telefone)
        errors.push("Telefone não pode estar vazio");
      if (
        this.fastDadosObrigatorios.id_pais_telefone &&
        !this.fastCadastro.id_pais_telefone
      )
        errors.push("DDI não pode estar vazio");
      return errors;
    },
    validaPasso2() {
      if (this.validaErrosForm().length) {
        this.validaErrosForm().forEach((e) => {
          this.exibeToasty(e, "error");
        });
      } else {
        this.steps = 3;
      }
    },
    mostraEnvio(documento) {
      this.fastDocumentoParaEnvio = documento;
      this.enviodocs.habilitaEnvio = true;
      // console.log("this.fastDocumentoParaEnvio", this.fastDocumentoParaEnvio);
    },
    async validaPasso3eFinaliza() {
      let obj = {
        token: this.token,
        cpf_cnpj: this.fastCadastro.cpf_cnpj,
        nome_razao: this.fastCadastro.nome_razao,
        sobrenome_fantasia: this.fastCadastro.sobrenome_fantasia,
        datanascimento_abertura: this.fastCadastro.datanascimento_abertura,
        sexo: this.fastCadastro.sexo,
        id_pais_pessoa: this.fastCadastro.id_pais_pessoa,
        naturalidade: this.fastCadastro.naturalidade,
        rg: this.fastCadastro.rg,
        orgao_emissor: this.fastCadastro.orgao_emissor,
        cep: this.fastCadastro.cep,
        uf: this.fastCadastro.uf,
        logradouro: this.fastCadastro.logradouro,
        bairro: this.fastCadastro.bairro,
        cidade: this.fastCadastro.cidade,
        complemento: this.fastCadastro.complemento,
        numero: this.fastCadastro.numero,
        id_pais_endereco: this.fastCadastro.id_pais_endereco,
        telefone: this.fastCadastro.telefone,
        id_pais_telefone: this.fastCadastro.id_pais_telefone,
      };
      return await new Promise((resolve, reject) => {
        try {
          fetch(
            settings.endApiFastEad +
              "api/fast_secretaria_turma_checkout_aluno/conclui_cadastro",
            this.fastAjaxOptions("POST", JSON.stringify(obj))
          )
            .then((response) => response.json())
            .then((res) => {
              if (res.success === false) {
                console.error(`${res.error ? res.error : ''} ${res.description ? res.description : ''}`);
                this.exibeToasty(`${res.error ? res.error : ''} ${res.description ? res.description : ''}`, "error");
              } else {
                this.fastCadastroConcluido.message =
                  "Cadastro finalizado, parabéns!";
                this.fastCadastroConcluido.status = true;
                resolve(res);
              }
            })
            .catch((error) => {
              console.log(error);
              this.exibeToasty(error, "error");
              reject(error);
            });
        } catch (e) {
          console.log(e);
          this.exibeToasty(e, "error");
          reject(e);
        }
      });
    },
    fileValidation(e) {
      console.log("file_validation" + e);
      return false;
    },
    async fileSuccess(rootFile, file, message) {
      let result = JSON.parse(JSON.parse(message));
      if (!result.error) {
        let obj = {
          token: this.token,
          Tipo_documento: this.fastDocumentoParaEnvio.tipo_documento,
          Path_documento: result.url,
        };
        return await new Promise((resolve, reject) => {
          try {
            fetch(
              settings.endApiFastEad +
                "api/fast_secretaria_turma_checkout_aluno/insere_documento",
              this.fastAjaxOptions("POST", JSON.stringify(obj))
            )
              .then((res) => {
                console.log("res" + res);
                this.fastDadosObrigatorios.fast_secretaria_matricula_requisito_documento.forEach(
                  (e) => {
                    if (
                      e.id_matricula_requisito_documento ===
                      this.fastDocumentoParaEnvio
                        .id_matricula_requisito_documento
                    ) {
                      e.docEnviado = true;
                    }
                  }
                );
                // console.log(
                //   "arrayAuxiliar",
                //   this.fastDadosObrigatorios
                //     .fast_secretaria_matricula_requisito_documento
                // );

                this.enviodocs.habilitaEnvio = false;
                resolve(res);
              })
              .catch((error) => {
                console.log(error);
                reject(error);
              });
          } catch (e) {
            reject(e);
          }
        });
      }
    },
    sendToWhatsAppWeb() {
      window.open(
        "https://api.whatsapp.com/send?phone=+5511994478241&text=Ol%C3%A1%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20o%20produto",
        "_blank"
      );
    },
    verificaSeDocJaFoiEnviado() {
      console.log(
        "fastDadosObrigatorios",
        this.fastDadosObrigatorios.fast_secretaria_matricula_requisito_documento
      );
      console.log("fastCadastro", this.fastCadastro.documentos_cadastrados);

      this.fastDadosObrigatorios.fast_secretaria_matricula_requisito_documento.map(
        (requisito) => {
          this.fastCadastro.documentos_cadastrados.forEach((documentoUpado) => {
            if (requisito.tipo_documento === documentoUpado.tipo_documento) {
              requisito.docEnviado = true;
            }
          });
        }
      );
    },
  },
};
</script>

<style scoped>
.body-page {
  height: 70vh;
}
.fasteadHeader {
  background-color: #e5eef4;
  height: 5rem;
}
.container {
  width: auto;
  min-height: 25em;
}
.steps-guide-active,
.steps-guide,
.btn {
  box-shadow: 3px 3px 2px rgba(0, 0, 0, 0.2);
}
.steps-guide-active,
.steps-guide {
  border: none;
  padding: 1em 3em;
  border-radius: 16px;
  transition: 0.5s;
  color: #fff;
  font-weight: 600;
}

.steps-guide {
  background-color: #e66e10;
}

.steps-guide-active {
  background-color: #015b97;
}

.steps-guide-disabled {
  background-color: #aaaaaa;
}

.steps-guide-active:hover,
.steps-guide:hover {
  opacity: 0.5;
}

.steps-header button:disabled {
  background-color: #dadada;
}

.steps-icon {
  color: #e66e10;
  font-size: 3em;
}

.btn {
  border-radius: 16px;
  padding: 0.7em 1.5em 0.5em;
  background-color: #e66e10;
  color: #fff;
  transition: 0.5s;
  font-weight: 600;
  align-items: center;
}

.btn-send-file {
  background-color: #bb2124;
}

.btn:hover {
  background-color: #015b97;
}

.btn-finaliza {
  background-color: #5cb85c;
}

.whastapp-icon {
  color: #fff;
  background-color: #25d366;
  padding: 15px;
  border-radius: 50%;
  font-size: 7em;
  position: fixed;
  left: 90%;
  top: 70%;
  z-index: 999;
  cursor: pointer;
}
</style>
